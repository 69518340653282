<template>
  <sdDrawer
      ref="ImportAddRef"
      :form="form"
      title="Import aanmaken"
      type="submit"
      btnText="Toevoegen"
      submitBtnText="Aanmaken"
  >
    <FormValidationWrap>
      <a-form ref="formRef" :model="form" layout="vertical" @finish="CreateImport">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Type" name="type"
                         :rules="{required: true,message: 'Dit veld is verplicht'}">
              <a-select v-model:value="form.type"
                        size="large" class="sDash_fullwidth-select"
                        :allowClear="true">
                <a-select-option name="type" v-for="(item,index) in ImportTypeOptions"
                                 :key="index" :value="item.value">
                  {{ item.key }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="form.type" :span="24" class="mb-25">
            <div class="bzekyi ant-alert ant-alert-info ant-alert-with-description" data-show="true">
              <span role="img" aria-label="info-circle" class="anticon anticon-info-circle ant-alert-icon"><svg
                focusable="false" class="" data-icon="info-circle" width="1em" height="1em" fill="currentColor"
                aria-hidden="true" viewBox="64 64 896 896"><path
                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path
                d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path></svg></span>
              <div class="ant-alert-content">
                <div class="ant-alert-message"></div>
                <div class="ant-alert-description">
                  Download een voorbeeldbestand voor het importeren van de gekozen type kunt door hieronder op downloaden te klikken (uitleg staat in het bestand):<br/>
                  <a v-if="form.type === 'ENERGYTARIFF'" download target="_blank" href="../../files/Voorbeeld%20Energietarieven.xlsx">Downloaden</a>
                  <a v-if="form.type === 'CHANGESTATUS'"  download target="_blank" href="../../files/Voorbeeld%20Status%20Change.xlsx">Downloaden</a>
                  <a v-if="form.type === 'SENDEXTERNAL'" download target="_blank" href="../../files/Voorbeeld%20Verzenden%20naar%20leverancier.xlsx">Downloaden</a>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Bestand" name="file" :rules="{required: true,message: 'Dit veld is verplicht'}">
              <a-upload-dragger :multiple="false" v-model:file="form.file" :remove="handleRemove" name="file"
                                :before-upload="beforeUpload">
                <a-button :disabled="form.file">
                  <upload-outlined></upload-outlined>
                  Selecteer een bestand
                </a-button>
              </a-upload-dragger>
            </a-form-item>
          </a-col>
        </a-row>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button size="large" class="btn-signin" type="primary" html-type="submit" :loading="loadingCreateImport"
                    :disabled="loadingCreateImport"> Importeren
          </a-button>
        </div>
      </a-form>
    </FormValidationWrap>
  </sdDrawer>
</template>
<script>
import {defineComponent, reactive, computed, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";
import {ImportTypeOptions} from "../../utility/enums";

const ImportAdd = defineComponent({
  name: 'ImportAdd',
  components: {
    FormValidationWrap,
  },
  data() {
    return {
      dateFormat: 'DD-MM-YYYY',
      loading: false,
    }
  },
  setup() {
    const {state, dispatch} = useStore();
    const loadingCreateImport = computed(() => state.imports.loadingCreateImport);
    const ImportAddRef = ref();
    const formRef = ref();
    const form = reactive({
      type: null,
      file: null,
    });

    const closeDrawer = () => {
      ImportAddRef.value.onClose();
      formRef.value.resetFields();
      dispatch('getImports', {page: state.imports.currentPage, pageSize: state.imports.pageSize});
    }

    const cleanImportForm = () => {
      form.type = null;
      form.file = null;
    };

    const CreateImport = async () => {
      let formData = new FormData();
      formData.append("file", form.file);
      formData.append("type", form.type);
      await dispatch('createImport', {value: formData, close: closeDrawer});
      await cleanImportForm();
    };

    const handleRemove = () => {
      form.file = null;
    };

    const beforeUpload = (file) => {
      form.file = file;
      return false;
    };

    return {
      form,
      CreateImport,
      ImportTypeOptions,
      loadingCreateImport,
      ImportAddRef,
      formRef,
      handleRemove,
      beforeUpload,
    };
  },
});

export default ImportAdd;
</script>
