<template>
  <CardToolbox>
    <sdPageHeader title="Import">
      <template #subTitle>
        <span class="title-counter">{{ count }} import{{ count > 0 && count < 2 ? '' : 's' }}</span>
      </template>
      <template #buttons>
        <ImportAdd v-if="$can('create', 'import')"/>
      </template>
    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <ImportTable/>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import ImportTable from './component/ImportTable';
import {computed, ref, defineComponent, onMounted} from 'vue';
import {useStore} from 'vuex';
import {Main, CardToolbox} from '../styled';
import ImportAdd from "./ImportAdd";

const ImportList = defineComponent({
  name: 'ImportList',
  components: {ImportAdd, Main, CardToolbox, ImportTable},
  data() {
    return {
      showEditDrawer: false,
    }
  },
  beforeUnmount() {
    this.beforeDestroy();
  },
  setup() {
    const {state, dispatch} = useStore();
    const searchData = computed(() => state.headerSearchData);
    const count = computed(() => state.imports.count);
    const selectedRowKeys = ref(0);
    const selectedRows = ref(0);
    let interval = ref(null)

    onMounted(() => {
      dispatch('getImports', {page: state.imports.currentPage, pageSize: state.imports.pageSize});
      interval = setTimeout(() => {
        dispatch('getImports', {page: state.imports.currentPage, pageSize: state.imports.pageSize});
      }, 30000)
    });

    const beforeDestroy = () => {
      clearTimeout(interval)
    };

    return {
      searchData,
      selectedRowKeys,
      selectedRows,
      count,
      beforeDestroy,
    };
  },
});

export default ImportList;
</script>
