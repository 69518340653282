<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
          :dataSource="importTableData"
          :columns="importTableColumns"
          :pagination="{
          defaultPageSize: pageSize,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
          @change="paginationChange"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../../user/style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useAbility} from "@casl/vue";

const importTableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Error',
    dataIndex: 'failReason',
    key: 'failReason',
  },
  {
    title: 'Aangemaakt op',
    dataIndex: 'createTimestamp',
    key: 'createTimestamp',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const ImportListTable = defineComponent({
  name: 'ImportListTable',
  components: {TableStyleWrapper, TableWrapper},
  setup() {
    const {state, dispatch} = useStore();
    const imports = computed(() => state.imports.imports);
    const count = computed(() => state.imports.count);
    const pageSize = computed(() => state.imports.pageSize);
    const currentPage = computed(() => state.imports.currentPage);
    const {can} = useAbility();
    // const handleDelete = (id) => {
    //   dispatch('deleteImport', id);
    // };
    // const handleEdit = (id) => {
    //   router.push(`${path}/${id}`);
    // };
    const resolveStatusVariant = status => {
      if (status === 'NEW') return {variant: 'pending', label: 'Nieuw'};
      if (status === 'PROCESSING') return {variant: 'pending', label: 'In behandeling'};
      if (status === 'FAILED') return {variant: 'inactive', label: 'Error'};
      if (status === 'FINISHED') return {variant: 'active', label: 'Afgerond'};
      if (status === 'EXPIRED') return {variant: 'inactive', label: 'Afgerond'};
      return {variant: '', label: 'Status onbekend'};
    }

    const resolveTypeVariant = type => {
      if (type === 'ENERGYTARIFF') return 'Energie-Tarieven';
      return {variant: '', label: 'Type onbekend'};
    }
    const importTableData = computed(() =>
        imports.value.map((item) => {
          const {id, createTimestamp, status, type, failReason, fileURL} = item;

          return {
            key: id,
            id: id,
            type: <span>{resolveTypeVariant(type)}</span>,
            createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
            status: <span
                className={`status-text ${resolveStatusVariant(status).variant}`}>{resolveStatusVariant(status).label}</span>,
            failReason: (status === 'FAILED' ? (failReason) : ('')),
            action: (
                <div className="table-actions">
                  {can('download', 'import') && status === 'FINISHED' ? (
                      <a href={fileURL} target="_blank">
                        <sdFeatherIcons type="download" size="16"/>
                      </a>
                  ) : ('')}
                  {status === 'PROCESSING' ? (
                      <a-spin size="small"/>
                  ) : ('')}
                </div>
            ),
          };
        }),
    );

    const rowSelection = {
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled import', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const paginationChange = async (event) => {
      await dispatch('setCurrentPageImport', event.current);
      await dispatch('getImports', {page: event.current, pageSize: state.user.pageSize});
    }

    return {
      pageSize,
      count,
      importTableColumns,
      importTableData,
      rowSelection,
      resolveStatusVariant,
      resolveTypeVariant,
      paginationChange,
      currentPage,
    };
  },
});

export default ImportListTable;
</script>
